import React from 'react';


const Load = () => {
    
    return (
        <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center" style={{backgroundColor: '#252c40', height: '100vh'}}>
            <div className="loadingio-spinner-bean-eater-r0ihjaywl67"><div className="ldio-w47kf3a5sw">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
            <h4 className="has-text-white mt-5 title is-4">กำลังโหลด...</h4>
        </div>
    );
}

export default Load;