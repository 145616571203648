import React, { ChangeEvent, KeyboardEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaLock } from "react-icons/fa";
import Swal from "sweetalert2";
import "../../i18n";
import { API_Post_Check, API_Post_Forgot } from "../../util/api";

interface ComponentStruct {
  username: string;
  setCode: (code: string) => void;
  setStep: (id: number) => void;
}

const Step2: React.VFC<ComponentStruct> = ({ setStep, setCode, username }) => {
  interface InputStruct {
    code: string;
  }

  const [Loading, setLoading] = useState<boolean>(false);

  interface ErrorStruct {
    code: boolean;
  }

  const SendAgain = (): void => {
    setLoading(true);
    API_Post_Forgot(username).then((res) => {
      if (res.status === 200) {
        Swal.fire({
          icon: "success",
          title: t("success"),
          text: "ส่งโค้ดยืนยันเสร็จสิ้น",
          timer: 5000,
          timerProgressBar: true,
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: t("warning"),
          text: t("unable"),
          timer: 5000,
          timerProgressBar: true,
        });
      }
      setLoading(false);
    });
  };

  const POSTAPI = (): void => {
    setLoading(true);
    API_Post_Check(username, Data.current.code)
      .then((res) => {
        if (res.status) {
          setCode(Data.current.code);
          setStep(2);
        } else {
          Swal.fire({
            icon: "warning",
            title: t("warning"),
            text: res.message,
            timer: 5000,
            timerProgressBar: true,
          });
        }
        setLoading(false);
      })
      .catch(() => {
        Swal.fire({
          icon: "warning",
          title: t("warning"),
          text: t("unable"),
          timer: 5000,
          timerProgressBar: true,
        });
        setLoading(false);
      });
  };

  const { t } = useTranslation();

  const onSubmit = (): void => {
    if (isNotEmpty(Data.current.code, "code")) {
      POSTAPI();
    }
  };

  const Data = useRef<InputStruct>({
    code: "",
  });

  const [Error, setError] = useState<ErrorStruct>({
    code: false,
  });

  const onEnterKey = (event: KeyboardEvent): void => {
    event.key === "Enter" && onSubmit();
  };

  const changeData = (event: ChangeEvent<HTMLInputElement>): void => {
    Data.current = { ...Data.current, [event.target.id]: event.target.value };
  };

  const isNotEmpty = (value: string, id: string): boolean => {
    if (value !== "") {
      if (value.length >= 4) {
        setError((prevState) => ({ ...prevState, [id]: false }));
        return true;
      } else {
        setError((prevState) => ({ ...prevState, [id]: true }));
        return false;
      }
    } else {
      document.getElementById(id)?.focus();
      setError((prevState) => ({ ...prevState, [id]: true }));
      document.getElementById(id)?.focus();
      return false;
    }
  };

  return (
    <>
      <div className="mb-2">
        <div className="field">
          <label className="label has-text-white" htmlFor="code">
            {t("code_forgot")}
          </label>
          <div className="control has-icons-left">
            <input
              disabled={Loading}
              autoComplete="true"
              autoFocus
              id="code"
              type="text"
              onKeyPress={onEnterKey}
              onChange={changeData}
              placeholder={t("code_forgot_paceholder")}
              className={`input ${Error.code ? "is-danger" : ""}`}
            />
            <span className="icon is-small is-left">
              <FaLock />
            </span>
          </div>
          {Error.code && (
            <p className="help is-danger">{t("code_forgot_error")}</p>
          )}
        </div>
      </div>
      <button
        onClick={() => onSubmit()}
        className={`button is-primary is-fullwidth mt-5 ${
          Loading ? "is-loading" : ""
        }`}
      >
        {t("confirm")}
      </button>
      <button
        onClick={() => SendAgain()}
        className={`button is-primary is-outlined is-fullwidth mt-5 ${
          Loading ? "is-loading" : ""
        }`}
      >
        {t("send_code")}
      </button>
      <button
        onClick={() => setStep(0)}
        className={`button is-primary is-outlined is-fullwidth mt-2 ${
          Loading ? "is-loading" : ""
        }`}
      >
        {t("back")}
      </button>
    </>
  );
};

export default Step2;
