import React from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import SideBar from '../component/sidebar';


const Error404 = () => {
    return (
        <>
        <div className="is-flex is-justify-content-center is-align-items-center" style={{height: '100vh'}}>
            <div className="is-flex is-flex-direction-column is-align-items-center has-text-centered">
                <FaTimesCircle className="mr-3 mb-3 has-text-danger mb-5" style={{fontSize: '150px'}} />
                <div>
                    <h1 className="has-text-danger title is-3"> เกิดข้อผิดพลาด 404</h1>
                    <h3 className="has-text-danger subtitle is-5">ไม่พบหน้าที่ท่านต้องการหา</h3>
                </div>
            </div>
        </div>
        </>
    );
}


export default Error404;