import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AuthProvider } from '../util/auth-context';
import View from './view';
import Error404 from './404';
import Login from './Login';
import Register from './Register';
import Loading from './Loading';
import Load from '../component/load';
import Policy from './Policy';
import Oauth from './Oauth';
import Forgot from './Forgot';
import Order from './Order';
import { DataProvider } from '../util/data-context';


const App = ()=> {
    return (
        <AuthProvider>
            <DataProvider>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/view" component={View} />
                        <Route exact path="/order" component={Order} />
                        <Route exact path="/load" component={Load} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/forgot" component={Forgot} />
                        <Route exact path="/oauth" component={Oauth} />
                        <Route exact path="/register" component={Register} />
                        <Route exact path="/policy" component={Policy} />
                        <Route exact path={['/', '/brand', '/brand/:id', '/product/:id']} component={Loading} />
                        <Route path="*" component={Error404} />
                    </Switch>
                </BrowserRouter>
            </DataProvider>
        </AuthProvider>
    );
}

export default App;