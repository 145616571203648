/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import SelectKind from './selectkind';
import SelectBrand from './selectbrand';
import { DataContext } from '../util/data-context';

interface SearchStruct {
    Set: (search_box : string, work : string, category : string, kind : string, brand : string) => void,
}

const Search : React.FC<SearchStruct> = (props) => {

    const dataContext = useContext(DataContext);
    const [Data, SetData] = useState({
        search_box: '',
        work: '',
        category: '',
        kind: '',
        brand: '',
    });

    const [Loading, setLoading] = useState(false);

    // Set State Input
    const SetInputState = (event: ChangeEvent<any>) => {
        SetData(preState => ({
            ...preState,
            [event.target.id]: event.target.value
        }));
    } 

    const setWork = (type : string, id : string) => {
        SetData(preState => ({
            ...preState,
            [type]: id
        }));
    }
    
    const SetBrand = (id : string) => {
        SetData(preState => ({
            ...preState,
            'brand': id
        }));
    }

    useEffect(() => {
        setLoading(true);
        const timeOutId = setTimeout(() => {
            setLoading(false);
            dataContext.page = 1;
            props.Set(Data.search_box, Data.work, Data.category, Data.kind, Data.brand);
        }, 800);
        return () => clearTimeout(timeOutId);
    }, [Data]);

    return (
        <div className="is-flex is-justify-content-flex-start navbar-bar">
                <div className="col-lg-12 mt-1 mb-1">
                    <div className="is-flex">
                            <div className="field">
                                <div className={`control has-icons-left ${Loading ? 'is-loading' : ''}`}>
                                    <input autoComplete="off" value={Data.search_box} onChange={SetInputState}
                                    id="search_box" autoFocus type="text" className={`input`} placeholder="ค้นหาชื่อสินค้า" />
                                    <span className="icon is-small is-left">
                                        <FaSearch />
                                    </span>

                                </div>
                            </div>
                        <SelectKind set={setWork} />
                        <SelectBrand set={SetBrand} />
                        <ReactTooltip id="typesearch" place="bottom" effect="solid" />
                    </div>
                </div>
        </div>
    );
}

export default Search;