import React, { useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { FaHome, FaTools, FaCity, FaSignOutAlt, FaHeart, FaAngleLeft } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { API_Logout } from '../util/api';
import { AuthContext } from '../util/auth-context';
import { DataContext } from '../util/data-context';

const SideBar = () => {

    interface Query {
        path: string
    }    

    const dataContext = useContext(DataContext);

    const query = useRouteMatch<Query>();
    
    const context = useContext(AuthContext);

    const history = useHistory();

    const logout = () => {
        Swal.fire({
            icon: 'warning',
            title: 'คำเตือน',
            text: 'คุณต้องการที่จะ ออกจากระบบ ใช่ หรือ ไม่?',
            showCancelButton: true,
            cancelButtonText: 'ไม่',
            showConfirmButton: true,
            confirmButtonText: 'ใช่',
            timer: 5000,
            timerProgressBar: true
        }).then(result => {
            if (result.value) {
                API_Logout().finally(() => {
                    Swal.fire({
                        icon: 'success',
                        title: 'สำเร็จ',
                        text: 'ออกจากระบบเสร็จสิ้น',
                        timer: 5000,
                        timerProgressBar: true,
                    }).then(() => {
                        context.auth[1](false);
                        localStorage.removeItem('token');
                        localStorage.removeItem('refresh_token');
                        history.push('/login');
                    });
                });
            }
        });
    }

    const linkandclearpage = (path : string) => {
        dataContext.page = 1;
        history.push(path);
    }

    const openQuickBOQ = () => {
        // @ts-ignore
        sketchup.report();
    }

    return (
        <>
        <div className="sidebar">
            {
                (query.path !== '/' && query.path !== '/brand') && <button data-for="sidetooltip" onClick={() => history.goBack()} className={`mb-3 mt-2`} data-tip="กลับ"><FaAngleLeft /></button>
            }
            <button data-for="sidetooltip" onClick={() => linkandclearpage('/')} className={`${query.path === '/' && 'active_sidebar'} mb-3 mt-2`} data-tip="หน้าหลัก"><FaHome /></button>

            {/* <button onClick={() => history.push('/favourite')} className={`${query.path === '/favourite' && 'active_sidebar'} mb-3 mt-2`} data-tip="รายการโปรด"><FaHeart /></button> */}

            <button data-for="sidetooltip" onClick={() => linkandclearpage('/brand')} className={`${query.path === '/brand' && 'active_sidebar'} mb-3`} data-tip="แบรนด์ต่างๆ"><FaCity /></button>

            <button data-for="sidetooltip" onClick={() => openQuickBOQ()} className={`mb-3`} data-tip="เครื่องมือถอดราคา"><FaTools /></button>

            {/* <button onClick={() => history.push('/setting')} className={`${query.path === '/setting' && 'active_sidebar'} mb-3 mt-auto`} data-tip data-for="Setting"><FaCog /></button>
            <ReactTooltip id="Setting" place="left" effect="solid">ตั้งค่า</ReactTooltip> */}

            <button data-for="sidetooltip" onClick={() => logout()} data-tip="ออกจากระบบ" style={{marginTop: 'auto'}}><FaSignOutAlt /></button>

        </div>
        <ReactTooltip id="sidetooltip" place="left" effect="solid" />
        </>
    );
}


export default SideBar;