import React, { useEffect, useState } from 'react';
import { API_Product, API_Detail, API_Image_Product } from '../util/api';
// import '@google/model-viewer';
import Swal from 'sweetalert2';

const View = () => {

    interface DetailStruct {
        header: string
        detail: string
    }

    const [Status, setStatus] = useState(false);
    const [Detail, setDetail] = useState<Array<DetailStruct>>([]);

    const [Data, setData] = useState({
        name_product : '' as string,
        price_product: 0 as number,
        image_product: '' as string,
        name_brand: '' as string,
        link: '' as string,
    });

    const Get_Data = (id : string) => {
        API_Product(id).then(res => {
            setData(res.data[0]);
            setStatus(true);
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'เกิดข้อผิดพลาดจากทางเซิฟเวอร์',
                timer: 5000,
                timerProgressBar: true
            });
        });
    }

    const Get_Detail = (id : string) => {
        API_Detail(id).then(res => {
            setDetail(res.data);
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'เกิดข้อผิดพลาดจากทางเซิฟเวอร์',
                timer: 5000,
                timerProgressBar: true
            });
        });
    }

    // @ts-ignore
    window.send_data = (id : string) => {
        if (id !== '') {
            Get_Data(id);
            Get_Detail(id);
            setStatus(true);
        } else {
            setStatus(false);
        }
    }

    useEffect(() => {
        //Get_Data("1");
        //Get_Detail("1");
    }, []);

    return (
        <div className="is-flex is-justify-content-center is-align-items-center" style={{height: '100%', minHeight:'100vh', background: '#252c40'}}>
            {
                Status ? 
                <>
                {
                    Data.name_product !== '' ?
                    <div className="column">
                        <div className="card mb-3">
                            <div className="card-content">
                                <div className="has-text-centered">
                                    <img src={API_Image_Product(Data.image_product)} width="100%" alt="NoImage" />
                                </div>
                                <h6 className="title is-6">ชื่อสินค้า : {Data.name_product}</h6>
                                <h6 className="title is-6">ราคา : {Data.price_product}</h6>
                                <h6 className="title is-6">แบนด์ : {Data.name_brand}</h6>
                                <button className="button is-link is-fullwidth" onClick={() => window.open(Data.link)}>ติดต่อ</button>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <div className="card-header-title">รายละเอียด</div>
                            </div>
                            <div className="card-content">
                                <table className="table is-hovered is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th>ลำดับ</th>
                                            <th>หัวข้อ</th>
                                            <th>รายละเอียด</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Detail.length > 0 &&
                                            Detail.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.header}</td>
                                                    <td>{item.detail}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    :
                    <h3 className="title is-3 has-text-white has-text-centered">ไม่พบสินค้าชิ้นนี้ในระบบ</h3>
                }
                </>
                :
                <h3 className="title is-3 has-text-white has-text-centered">กรุณาเลือกโมเดลก่อน</h3>
            }
        </div>
    );
}

export default View;