import React, { useEffect, useState } from 'react';
import { FaDownload, FaEye, FaAngleRight, FaCube, FaImage } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import SideBar from '../component/sidebar';
// import '@google/model-viewer';
import { API_Product, API_Image_Product, API_gltf_product, API_Detail } from '../util/api';
import Swal from 'sweetalert2';

interface DetailStruct {
    header: string
    detail: string
}

const Product_id = () => {

    const [ShowMode, setShowMode] = useState(false);
    const [Loading, setLoading] = useState(false);

    const [Data, setData] = useState({
        name_product : '' as string,
        name_category: '' as string,
        name_work: '' as string,
        name_kind: '' as string,
        price_product: 0 as number,
        image_product: '' as string,
        view_product: 0 as number,
        material: false as Boolean,
        download_product: 0 as number,
        skp_product: '' as string,
        gltf_product: '' as string,
        link: '' as string
    });

    const [Detail, setDetail] = useState<Array<DetailStruct>>([]);

    const { id } = useParams<{
        id : string
    }>();

    const openModel = () => {
        setLoading(true);
        // @ts-ignore
        sketchup.place(Data, localStorage.getItem('token'));

        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    const Get_Data = () => {
        API_Product(id).then(res => {
            setData(res.data[0]);
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'เกิดข้อผิดพลาดจากทางเซิฟเวอร์',
                timer: 5000,
                timerProgressBar: true
            });
        });
    }

    const Get_Detail = () => {
        API_Detail(id).then(res => {
            setDetail(sortdetail(res.data));
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'เกิดข้อผิดพลาดจากทางเซิฟเวอร์',
                timer: 5000,
                timerProgressBar: true
            });
        });
    }

    const sortdetail = (data: Array<DetailStruct>) => {
        if(data[0].header.substring(0, 4).indexOf('.') > 0) {
            data = data.sort((a, b) => {
                if(parseInt(a.header.split(".")[0]) > parseInt(b.header.split(".")[0])) return 1;
                if(parseInt(a.header.split(".")[0]) < parseInt(b.header.split(".")[0])) return -1;
                return 0;
            });
            for(let index in data) {
                data[index].header = data[index].header.split(".")[1];
            }
        } else if (data[0].header.substring(0, 4).match(/\d+/g)) {
            data = data.sort((a, b) => {
                if(parseInt(a.header.split(" ")[0]) > parseInt(b.header.split(" ")[0])) return 1;
                if(parseInt(a.header.split(" ")[0]) < parseInt(b.header.split(" ")[0])) return -1;
                return 0;
            });
            for(let index in data) {
                data[index].header = data[index].header.replace(/\d+/g, '');
            }
        }
        return data;
    }

    useEffect(() => {
        Get_Data();
        Get_Detail();
    }, []);

    return (
        <>
        <SideBar />
        <div className="download">
            {
                Loading ?
                <button>กำลังโหลด...</button>
                :
                <button onClick={() => openModel()}><FaDownload /> ดาวน์โหลดโมเดลนี้</button>
            }
        </div>
        <div style={{'marginLeft': '60px'}}>
            <div className="columns" style={{marginTop: '0'}}>
                <div className="column">
                    <div className="card has-text-right">
                        <div className="is-fullwidth has-text-centered mb-1" style={{height: '350px'}}>
                            {
                                Data.name_product && (
                                    ShowMode ?
                                    <>
                                        {/* @ts-ignore */}
                                        {/* <model-viewer style={{width: '100%', height: '100%'}} src={API_gltf_product(Data.gltf_product)} alt="A 3D model of an astronaut" auto-rotate camera-controls></model-viewer> */}
                                    </>
                                    :
                                    <>
                                        <img src={API_Image_Product(Data.image_product)} alt="NoImage" width="300px" />
                                    </>
                                )
                            }
                        </div>
                        <div className="p-3">
                        {
                            ShowMode ?
                            <>
                                <button className="button is-link" onClick={() => setShowMode(false)} data-tip data-for="Image"><FaImage /></button>
                                <ReactTooltip className="tooltip-z" id="Image" place="left" effect="solid">รูปภาพ</ReactTooltip>
                            </>
                            :
                            <>
                                <button className="button is-link" onClick={() => setShowMode(true)} data-tip data-for="Cube"><FaCube /></button>
                                <ReactTooltip className="tooltip-z" id="Cube" place="left" effect="solid">โมเดล 3D</ReactTooltip>
                            </>
                        }
                        </div>
                    </div>
                </div>
                <div className="column" style={{marginBottom: '70px'}}>
                    <div className="card">
                        <div className="card-content">
                            <h5 className="title is-5 mb-2">{Data.name_product}</h5>
                            <p><b>{Data.name_work}</b> <FaAngleRight /> <b>{Data.name_category}</b> <FaAngleRight /> {Data.name_kind}</p>
                            <div className="is-flex mb-3">
                                {/* <div className="col-lg-4 text-center">
                                    <h6>คะแนน 5/5</h6>
                                    <FaStar/>
                                    <FaStar/>
                                    <FaStar/>
                                    <FaStar/>
                                    <FaStar/>
                                </div> */}
                                <div className="column is-6 has-text-centered">
                                    <h6 className="title is-6">
                                        <FaEye /> คนดู
                                    </h6>
                                    {Data.view_product}
                                </div>
                                <div className="column is-6 has-text-centered">
                                    <h6 className="title is-6">
                                        <FaDownload /> ดาวน์โหลด
                                    </h6>
                                    {Data.download_product}
                                </div>
                            </div>
                            <h5 className="title is-6">ราคา ฿{Data.price_product} </h5>
                            <button className="button is-link is-fullwidth" onClick={() => window.open(Data.link)}>ติดต่อ</button>
                            <hr/>
                            <p>รายละเอียด</p>
                            <table className="table is-fullwidth is-hoverable">
                                <tbody>
                                    {
                                        Detail.length  > 0?
                                        Detail.map((item, index) => (
                                            <tr key={index}>
                                                <td width="25%">{item.header}</td>
                                                <td>{item.detail}</td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td className="has-text-centered" colSpan={2}>ไม่มีรายละเอียด</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default Product_id;