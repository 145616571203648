import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Logo from "../asset/logo.png";
import Step1 from "../component/forgot/step1";
import Step2 from "../component/forgot/step2";
import Step3 from "../component/forgot/step3";
import "../i18n";

const Forgot = () => {
  interface DataStruct {
    username: string;
    code: string;
    password: string;
  }

  const { t } = useTranslation();

  const [Step, setStep] = useState<number>(0);

  const Data = useRef<DataStruct>({
	  username: "",
	  code: "",
	  password: "",
  });

  const setBackground = (): void => {
    document.body.style.backgroundColor = "#252c40";
  };

  const StepController = () => {
    switch (Step) {
      case 0:
        return <Step1 setUsername={(username) => Data.current.username = username} setStep={(id) => setStep(id)} />;
      case 1:
        return <Step2 username={Data.current.username} setCode={(code) => Data.current.code = code} setStep={(id) => setStep(id)} />;
      case 2:
      	return <Step3 username={Data.current.username} code={Data.current.code} setStep={(id) => setStep(id)}  />;
      default:
        return <Step1 setUsername={(username) => Data.current.username = username} setStep={(id) => setStep(id)} />;
    }
  };

  useEffect(() => {
    setBackground();
  }, []);

  return (
    <div className="container">
      <div
        className="is-flex is-flex-column is-justify-content-center is-align-items-center"
        style={{ minHeight: "100vh", maxHeight: "100%" }}
      >
        <div style={{ width: "400px" }}>
          <img src={Logo} alt="NoImage" className="has-text-centered mb-0" />
          <h1 className="has-text-primary is-size-4 mt-0 has-text-centered">
            {t("forgot")}
          </h1>
          {StepController()}
        </div>
      </div>
    </div>
  );
};

export default Forgot;
