export const TRANSLATIONS_EN = {
	"login": "Login",
	"register": "Register",
	"rememberme": "Rememberme",
	"showpassword": "Show Password",
	"forgot": "Forgot",
	"username": "Username",
	"username_placeholder": "your email",
	"username_error": "username error",
	"password": "Password",
	"password_placeholder": "password placeholder",
	"password_error": "password error",
	"firstname": "firstname",
	"fistname_placeholder": "your firstname",
	"lastname": "lastname",
	"lastname_placeholder": "your lastname",
	"tel": "tel",
	"tel_paceholder": "your tel",
	"gender": "gender",
	"gender_paceholder": "your gender",
	"male": "male",
	"female": "female",
	"job": "job",
	"job_paceholder": "your job",
	"country": "country",
	"country_paceholder": "your country",
	"termsofuse": "Terms of Use",
	"privacy": "Privacy",
	"back": "Back",
	"error": "Error",
	"success": "Success",
	"unable": "unable to connect",
}