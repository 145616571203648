import React, {
  useState,
  ChangeEvent,
  KeyboardEvent,
  useRef,
  useEffect,
} from "react";
import { useHistory } from "react-router-dom";
import {
  FaUser,
  FaLock,
  FaTransgender,
  FaHardHat,
  FaAddressCard,
  FaGlobe,
  FaPhone,
} from "react-icons/fa";
import { API_Member, API_Job } from "../util/api";
import Swal from "sweetalert2";
import Logo from "../asset/logo.png";
import axios from "axios";
import countrydata from "../asset/country.json";
import { useTranslation } from "react-i18next";
import "../i18n";

const Register = () => {
  interface JobStruct {
    id_job: string;
    name_job: string;
  }

  const { t } = useTranslation();

  const Data = useRef({
    username: "",
    password: "",
    firstname: "",
    lastname: "",
    tel: "",
    gender: "",
    job: "",
    country: "",
  });

  const [Error, setError] = useState({
    username: false,
    password: false,
    firstname: false,
    lastname: false,
    tel: false,
    gender: false,
    job: false,
    country: false,
    accept: false,
  });

  const [Jobs, setJobs] = useState<Array<JobStruct>>([]);

  const history = useHistory();

  const changeData = (event: ChangeEvent<any>): void => {
    let data = {
      ...Data.current,
      [event.target.id]: event.target.value,
    };
    Data.current = data;
  };

  const onSubmitRegister = () => {
    if (
      Check_Email(Data.current.username, "username") &&
      Check_More_Ten(Data.current.password, "password") &&
      Check_Empty(Data.current.firstname, "firstname") &&
      Check_Empty(Data.current.lastname, "lastname") &&
      Check_More_Ten(Data.current.tel, "tel") &&
      Check_Empty(Data.current.gender, "gender") &&
      Check_Empty(Data.current.job, "job") &&
      Check_Empty(Data.current.country, "country") &&
      Error.accept
    ) {
      Swal.fire({
        icon: "warning",
        title: t("warning"),
        text: "คุณต้องการที่จะ สมัครสมาชิก ใช่ หรือ ไม่?",
        showCancelButton: true,
        cancelButtonText: t("yes"),
        showConfirmButton: true,
        confirmButtonText: t("no"),
      }).then(() => {
        API_Member(
          Data.current.username,
          Data.current.password,
          Data.current.firstname,
          Data.current.lastname,
          Data.current.gender,
          Data.current.job,
          Data.current.tel,
          Data.current.country
        )
          .then((res) => {
            Swal.fire({
              icon: res.status ? "success" : "error",
              title: res.status ? t("success") : t("error"),
              text: res.message,
              timer: 5000,
              timerProgressBar: true,
            }).then((data) => {
              if (res.status) {
                if (data.value) {
                  window.location.href = "/login";
                }
              }
            });
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: t("error"),
              text: t("unable"),
              timer: 5000,
              timerProgressBar: true,
            });
          });
      });
    }
  };

  const onEnterKey = (event: KeyboardEvent): void => {
    event.key === "Enter" && onSubmitRegister();
  };

  const Check_Empty = (value: string, id: string): boolean => {
    if (value !== "") {
      setError((prevState) => ({ ...prevState, [id]: false }));
      return true;
    } else {
      document.getElementById(id)?.focus();
      setError((prevState) => ({ ...prevState, [id]: true }));
      document.getElementById(id)?.focus();
      return false;
    }
  };

  const Check_Email = (value: string, id: string): boolean => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(value))) {
      setError((prevState) => ({ ...prevState, [id]: false }));
      return true;
    } else {
      document.getElementById(id)?.focus();
      setError((prevState) => ({ ...prevState, [id]: true }));
      document.getElementById(id)?.focus();
      return false;
    }
  };

  const Get_Country = () => {
    axios.get("https://ipapi.co/country_name/").then((res) => {
      Data.current.country = res.data;
      let data = document.getElementById("country") as any;
      data.value = res.data;
    });
  };

  const Check_More_Ten = (value: string, id: string): boolean => {
    let status = false;

    if (value !== "") {
      if (value.length >= 10) {
        status = true;
      }
    }

    if (status) {
      setError((preState) => ({
        ...preState,
        [id]: false,
      }));
      return true;
    } else {
      setError((preState) => ({
        ...preState,
        [id]: true,
      }));
      document.getElementById(id)?.focus();
      return false;
    }
  };

  const Get_Jobs = () => {
    API_Job()
      .then((res) => {
        setJobs(res.data);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: t("error"),
          text: t("unable"),
          timer: 5000,
          timerProgressBar: true,
        });
      });
  };

  const setBackground = () => {
    document.body.style.backgroundColor = "#252c40";
  };

  useEffect(() => {
    Get_Country();
    Get_Jobs();
    setBackground();
  }, []);

  return (
    <>
      <div className="container">
        <div
          className="is-flex is-flex-column is-justify-content-center"
          style={{ minHeight: "100vh", maxHeight: "100%" }}
        >
          <div style={{ width: "400px" }}>
            <img src={Logo} alt="NoImage" className="has-text-centered" />
            <form>
              <div className="mb-2">
                <div className="field">
                  <label className="label has-text-white" htmlFor="username">
                    {t("username")}
                  </label>
                  <div className="control has-icons-left">
                    <input
                      autoComplete="true"
                      autoFocus
                      id="username"
                      type="email"
                      onKeyPress={onEnterKey}
                      onChange={changeData}
                      placeholder={t("username_placeholder")}
                      className={`input ${Error.username ? "is-danger" : ""}`}
                    />
                    <span className="icon is-small is-left">
                      <FaUser />
                    </span>
                  </div>
                  {Error.username && (
                    <p className="help is-danger">{t("username_error")}</p>
                  )}
                </div>
              </div>
              <div className="mb-2">
                <div className="field">
                  <label className="label has-text-white" htmlFor="password">
                    {t("password")}
                  </label>
                  <div className="control has-icons-left">
                    <input
                      autoComplete="true"
                      id="password"
                      type="password"
                      onKeyPress={onEnterKey}
                      onChange={changeData}
                      placeholder={t("password_placeholder")}
                      className={`input ${Error.password ? "is-danger" : ""}`}
                    />
                    <span className="icon is-small is-left">
                      <FaLock />
                    </span>
                  </div>
                  {Error.password && (
                    <p className="help is-danger">{t("password_error")}</p>
                  )}
                </div>
              </div>
              <div className="mb-2">
                <div className="field">
                  <label className="label has-text-white" htmlFor="firstname">
                    {t("firstname")}
                  </label>
                  <div className="control has-icons-left">
                    <input
                      autoComplete="true"
                      id="firstname"
                      type="text"
                      onKeyPress={onEnterKey}
                      onChange={changeData}
                      placeholder={t("fistname_placeholder")}
                      className={`input ${Error.firstname ? "is-danger" : ""}`}
                    />
                    <span className="icon is-small is-left">
                      <FaAddressCard />
                    </span>
                  </div>
                  {Error.firstname && (
                    <p className="help is-danger">{t("firstname_error")}</p>
                  )}
                </div>
              </div>
              <div className="mb-2">
                <div className="field">
                  <label className="label has-text-white" htmlFor="lastname">
                    {t("lastname")}
                  </label>
                  <div className="control has-icons-left">
                    <input
                      autoComplete="true"
                      id="lastname"
                      type="text"
                      onKeyPress={onEnterKey}
                      onChange={changeData}
                      placeholder={t("lastname_placeholder")}
                      className={`input ${Error.lastname ? "is-danger" : ""}`}
                    />
                    <span className="icon is-small is-left">
                      <FaAddressCard />
                    </span>
                  </div>
                  {Error.lastname && (
                    <p className="help is-danger">{t("lastname_error")}</p>
                  )}
                </div>
              </div>
              <div className="mb-2">
                <div className="field">
                  <label className="label has-text-white" htmlFor="tel">
                    {t("tel")}
                  </label>
                  <div className="control has-icons-left">
                    <input
                      autoComplete="true"
                      id="tel"
                      type="text"
                      onKeyPress={onEnterKey}
                      onChange={changeData}
                      placeholder={t("tel_paceholder")}
                      className={`input ${Error.tel ? "is-danger" : ""}`}
                    />
                    <span className="icon is-small is-left">
                      <FaPhone />
                    </span>
                  </div>
                  {Error.tel && (
                    <p className="help is-danger">{t("tel_error")}</p>
                  )}
                </div>
              </div>
              <div className="mb-2">
                <div className="field">
                  <label className="label has-text-white" htmlFor="gender">
                    {t("gender")}
                  </label>
                  <div className="control has-icons-left">
                    <select
                      id="gender"
                      onChange={changeData}
                      defaultValue=""
                      className={`input ${Error.gender ? "is-danger" : ""}`}
                    >
                      <option value="" disabled>
                        {t("gender_paceholder")}
                      </option>
                      <option value="0">{t("male")}</option>
                      <option value="1">{t("female")}</option>
                    </select>
                    <span className="icon is-small is-left">
                      <FaTransgender />
                    </span>
                  </div>
                  {Error.gender && (
                    <p className="help is-danger">{t("gender_error")}</p>
                  )}
                </div>
              </div>
              <div className="mb-2">
                <div className="field">
                  <label className="label has-text-white" htmlFor="job">
                    {t("job")}
                  </label>
                  <div className="control has-icons-left">
                    <select
                      id="job"
                      onChange={changeData}
                      defaultValue=""
                      className={`input ${Error.job ? "is-danger" : ""}`}
                    >
                      <option value="" disabled>
                        {t("job_paceholder")}
                      </option>
                      {Jobs.map((item, index) => (
                        <option key={index} value={item.id_job}>
                          {item.name_job}
                        </option>
                      ))}
                    </select>
                    <span className="icon is-small is-left">
                      <FaHardHat />
                    </span>
                  </div>
                  {Error.job && (
                    <p className="help is-danger">{t("job_error")}</p>
                  )}
                </div>
              </div>
              <div className="mb-2">
                <div className="field">
                  <label className="label has-text-white" htmlFor="country">
                    {t("country")}
                  </label>
                  <div className="control has-icons-left">
                    <select
                      id="country"
                      onChange={changeData}
                      defaultValue=""
                      className={`input ${Error.country ? "is-danger" : ""}`}
                    >
                      <option value="" disabled>
                        {t("country_paceholder")}
                      </option>
                      {countrydata.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <span className="icon is-small is-left">
                      <FaGlobe />
                    </span>
                  </div>
                  {Error.country && (
                    <p className="help is-danger">{t("country_error")}</p>
                  )}
                </div>
              </div>
              <div className="field mt-4">
                <div className={`control`}>
                  <label
                    className={`checkbox ${
                      Error.accept ? "has-text-white" : "has-text-danger"
                    }`}
                  >
                    <input
                      onChange={() =>
                        setError((prev) => ({ ...prev, accept: !prev.accept }))
                      }
                      type="checkbox"
                    />{" "}
                    {t("termsofuse")}{" "}
                    <a href="/policy" target="_blank">
                      {t("privacy")}
                    </a>
                  </label>
                </div>
              </div>
            </form>
            <button
              onClick={() => onSubmitRegister()}
              className="button button is-primary is-fullwidth mt-3"
            >
              {t("register")}
            </button>
            <button
              onClick={() => history.push("/")}
              className="button is-outlined is-primary is-fullwidth mb-4 mt-2"
            >
              {t("back")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
