import React, { ChangeEvent, useEffect, useRef, useState, KeyboardEvent } from 'react';
import Logo from '../asset/logo.png';
import { API_Job } from '../util/api';
import Swal from 'sweetalert2';
import { FaUser, FaGlobe, FaPhone } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import countrydata from '../asset/country.json';
import axios from 'axios';
import { API_Member } from '../util/api';

interface Strcut {
	back: () => void,
	data: {
		username: string,
		password: string,
		firstname: string,
		lastname: string,
		status: number,
	}
}

const Oauth = (props : Strcut) => {

	interface JobStruct {
		id_job: string
		name_job: string
	}

	const Data = useRef({
		gender: '',
		job: '',
		country: '',
		tel: '',
	});

	const [Error, setError] = useState({
		gender: false,
		job: false,
		country: false,
		tel: false,
		accept: false,
	});

	const [Jobs, setJobs] = useState<Array<JobStruct>>([]);


	const onEnterKey = (event: KeyboardEvent) : void => {
		event.key === 'Enter' && onSubmitRegister();
	}

	const history = useHistory();

	const changeData = (event: ChangeEvent<any>) : void => {
		let data = {
		...Data.current,
		[event.target.id]:event.target.value
		}
		Data.current = data;
	}

	const setBackground = () => {
		document.body.style.backgroundColor = "#252c40";
	}

	const Get_Jobs = () => {
		API_Job().then((res) => {
		setJobs(res.data);
		}).catch(() => {
		Swal.fire({
			icon: 'error',
			title: 'เกิดข้อผิดพลาด',
			text: 'เกิดข้อผิดพลาดจากทางเซิฟเวอร์',
			timer: 5000,
			timerProgressBar: true
		});
		});
	}

	const onSubmitRegister = () => {
			if (
			Check_Empty(Data.current.tel, 'tel') &&
			Check_Empty(Data.current.gender, 'gender') &&
			Check_Empty(Data.current.job, 'job') &&
			Check_Empty(Data.current.country, 'country') &&
			Error.accept
			) {
			Swal.fire({
				icon: 'warning',
				title: 'คำเตือน',
				text: 'คุณต้องการที่จะ สมัครสมาชิก ใช่ หรือ ไม่?',
				showCancelButton: true,
				cancelButtonText: 'ไม่',
				showConfirmButton: true,
				confirmButtonText: 'ใช่'
			}).then(() => {
				API_Member(props.data.username, props.data.password, props.data.firstname, props.data.lastname, Data.current.gender, 
					Data.current.job, Data.current.tel, Data.current.country, props.data.status
				).then(res => {
				Swal.fire({
					icon: res.status ? 'success' : 'error',
					title: res.status ? 'สำเร็จ' : 'เกิดข้อผิดพลาด',
					text: res.message,
					timer: 5000,
					timerProgressBar: true
				}).then(data => {
					if (res.status) {
						if (data.value) {
							props.back();
						}
					}
				});
				}).catch(() => {
				Swal.fire({
					icon: 'error',
					title: 'เกิดข้อผิดพลาด',
					text: 'เกิดข้อผิดพลาดจากทางเซิฟเวอร์',
					timer: 5000,
					timerProgressBar: true
				});
				});
			});
			}
	}

	const Check_Empty = (value : string, id : string) : boolean => {
		if (value !== '') {
		setError(prevState => ({...prevState, [id]: false}));
		return true;
		} else {
		document.getElementById(id)?.focus();
		setError(prevState => ({...prevState, [id]: true}));
		document.getElementById(id)?.focus();
		return false;
		}
	}

	const Get_Country = () => {
		axios.get("https://ipapi.co/country_name/").then(res => {
			Data.current.country = res.data;
			let data = document.getElementById("country") as any
			data.value = res.data;
		});
	}

	useEffect(() => {
		Get_Country();
		Get_Jobs();
		setBackground();
	}, []);

	return (
		<>
					<div style={{'width': '400px'}}>
						<img src={Logo} alt="NoImage" className="has-text-centered" />
						<form>
							<div className="mb-2">
								<div className="field">
									<label className="label has-text-white" htmlFor="tel">เบอร์โทรติดต่อ</label>
									<div className="control has-icons-left">
									<input autoFocus autoComplete="true" id="tel" type="text" onKeyPress={onEnterKey} onChange={changeData} placeholder="เบอร์โทรของท่าน" className={`input ${Error.tel ? 'is-danger' : ''}`} />
									<span className="icon is-small is-left">
										<FaPhone />
									</span>

									</div>
									{
										Error.tel &&
										<p className="help is-danger">กรุณากรอก เบอร์โทรของท่าน ให้ถูกต้อง</p>
									}
								</div>
							</div>
							<div className="mb-2">
								<div className="field">
									<label className="label has-text-white" htmlFor="gender">เพศ</label>
									<div className="control has-icons-left">
									<select id="gender" onChange={changeData} defaultValue="" className={`input ${Error.gender ? 'is-danger' : ''}`}>
										<option value="" disabled>กรุณาเลือกเพศ</option>
										<option value="0">ชาย</option>
										<option value="1">หญิง</option>
									</select>
									<span className="icon is-small is-left">
										<FaUser />
									</span>

									</div>
									{
									Error.gender &&
									<p className="help is-danger">กรุณากรอก เพศ ให้ถูกต้อง</p>
									}
								</div>
							</div>
							<div className="mb-2">
								<div className="field">
									<label className="label has-text-white" htmlFor="job">อาชีพ</label>
									<div className="control has-icons-left">
									<select id="job" onChange={changeData} defaultValue="" className={`input ${Error.job ? 'is-danger' : ''}`}>
										<option value="" disabled>กรุณาเลือกอาชีพ</option>
										{
										Jobs.map((item, index) => (
											<option key={index} value={item.id_job}>{item.name_job}</option>
										))
										}
									</select>
									<span className="icon is-small is-left">
										<FaUser />
									</span>

									</div>
									{
									Error.job &&
									<p className="help is-danger">กรุณากรอก อาชีพ ให้ถูกต้อง</p>
									}
								</div>
							</div>
							<div className="mb-2">
								<div className="field">
									<label className="label has-text-white" htmlFor="country">ประเทศ</label>
									<div className="control has-icons-left">
									<select id="country" onChange={changeData} defaultValue="" className={`input ${Error.country ? 'is-danger' : ''}`}>
										<option value="" disabled>เลือกประเทศ</option>
										{
											countrydata.map((item, index) => (
												<option key={index} value={item.name}>{item.name}</option>
											))
										}
									</select>
									<span className="icon is-small is-left">
										<FaGlobe />
									</span>

									</div>
									{
										Error.country &&
										<p className="help is-danger">กรุณากรอก ประเทศ ให้ถูกต้อง</p>
									}
								</div>
							</div>
							<div className="field mt-4">
								<div className={`control`}>
									<label className={`checkbox ${Error.accept ? 'has-text-white' : 'has-text-danger'}`}>
									<input onChange={() => setError(prev => ({...prev, accept: !prev.accept})) } type="checkbox" /> ข้อตกลงในการใช้งาน <a href="/policy" target="_blank">ความเป็นส่วนตัว</a>
									</label>
								</div>
							</div>
						</form>
						<button onClick={() => onSubmitRegister()} className="button button is-primary is-fullwidth mt-3">สมัครสมาชิก</button>
						<button onClick={() => props.back()} className="button is-outlined is-primary is-fullwidth mb-4 mt-2">กลับ</button>
					</div>
		</>
	);
}

export default Oauth;