import React, { createContext, useRef, useState } from 'react';

export const DataContext = createContext<any>([]);

export const DataProvider : React.FC = ({ children }) => {

    const Page = useRef<number>(1);

    const store = {
        page: Page.current,
    }

    return (
        <DataContext.Provider value={store}>
            { children }
        </DataContext.Provider>
    )
}
