import React, { ChangeEvent, KeyboardEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaLock } from "react-icons/fa";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import "../../i18n";
import { API_Post_Rest } from "../../util/api";

interface ComponentStruct {
  username: string;
  code: string;
  setStep: (id: number) => void;
}

const Step3: React.VFC<ComponentStruct> = ({ setStep, username, code }) => {
  interface InputStruct {
    newpassword: string;
    confirmpassword: string;
  }

  interface ErrorStruct {
    newpassword: boolean;
    confirmpassword: boolean;
  }

  const history = useHistory();

  const { t } = useTranslation();

  const onSubmit = (): void => {
    if (
      isPassword(Data.current.newpassword, "newpassword") &&
      confirmPassword(Data.current.confirmpassword, "confirmpassword")
    ) {
      PostAPI();
    }
  };

  const PostAPI = (): void => {
    setLoading(true);
    API_Post_Rest(username, code, Data.current.confirmpassword)
      .then((res) => {
        if (res.status) {
          Swal.fire({
            icon: "success",
            title: t("success"),
            text: "เปลื่ยนรหัสผ่านเสร็จสิ้น",
            timer: 5000,
            timerProgressBar: true,
          }).then(() => {
            history.push("/login");
          });
        } else {
          Swal.fire({
            icon: "error",
            title: t("error"),
            text: res.message,
            timer: 5000,
            timerProgressBar: true,
          });
        }
        setLoading(false);
      })
      .catch(() => {
        Swal.fire({
          icon: "warning",
          title: t("warning"),
          text: t("unable"),
          timer: 5000,
          timerProgressBar: true,
        });
        setLoading(false);
      });
  };

  const Data = useRef<InputStruct>({
    newpassword: "",
    confirmpassword: "",
  });

  const [Loading, setLoading] = useState<boolean>(false);

  const [Error, setError] = useState<ErrorStruct>({
    newpassword: false,
    confirmpassword: false,
  });

  const onEnterKey = (event: KeyboardEvent): void => {
    event.key === "Enter" && onSubmit();
  };

  const changeData = (event: ChangeEvent<HTMLInputElement>): void => {
    Data.current = { ...Data.current, [event.target.id]: event.target.value };
  };

  const isPassword = (value: string, id: string): boolean => {
    if (value.length >= 10) {
      setError((prevState) => ({ ...prevState, [id]: false }));
      return true;
    } else {
      document.getElementById(id)?.focus();
      setError((prevState) => ({ ...prevState, [id]: true }));
      document.getElementById(id)?.focus();
      return false;
    }
  };

  const confirmPassword = (value: string, id: string): boolean => {
    if (value.length >= 10) {
      if (Data.current.newpassword === Data.current.confirmpassword) {
        setError((prevState) => ({ ...prevState, [id]: false }));
        return true;
      } else {
        setError((prevState) => ({ ...prevState, [id]: true }));
        return false;
      }
    } else {
      document.getElementById(id)?.focus();
      setError((prevState) => ({ ...prevState, [id]: true }));
      document.getElementById(id)?.focus();
      return false;
    }
  };

  return (
    <>
      <div className="mb-2">
        <div className="field">
          <label className="label has-text-white" htmlFor="code">
            {t("newpassword")}
          </label>
          <div className="control has-icons-left">
            <input
              autoComplete="true"
              disabled={Loading}
              autoFocus
              id="newpassword"
              type="text"
              onKeyPress={onEnterKey}
              onChange={changeData}
              placeholder={t("password_placeholder")}
              className={`input ${Error.newpassword ? "is-danger" : ""}`}
            />
            <span className="icon is-small is-left">
              <FaLock />
            </span>
          </div>
          {Error.newpassword && (
            <p className="help is-danger">{t("password_error")}</p>
          )}
        </div>
        <div className="field">
          <label className="label has-text-white" htmlFor="code">
            {t("confirmpassword")}
          </label>
          <div className="control has-icons-left">
            <input
              disabled={Loading}
              autoComplete="true"
              id="confirmpassword"
              type="text"
              onKeyPress={onEnterKey}
              onChange={changeData}
              placeholder={t("password_placeholder")}
              className={`input ${Error.confirmpassword ? "is-danger" : ""}`}
            />
            <span className="icon is-small is-left">
              <FaLock />
            </span>
          </div>
          {Error.confirmpassword && (
            <p className="help is-danger">{t("password_error")}</p>
          )}
        </div>
      </div>
      <button
        onClick={() => onSubmit()}
        className={`button is-primary is-fullwidth mt-5 ${
          Loading ? "is-loading" : ""
        }`}
      >
        {t("confirm")}
      </button>
      <button
        onClick={() => setStep(0)}
        className={`button is-primary is-outlined is-fullwidth mt-2 ${
          Loading ? "is-loading" : ""
        }`}
      >
        {t("back")}
      </button>
    </>
  );
};

export default Step3;
