import React, { ChangeEvent, KeyboardEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEnvelope } from "react-icons/fa";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import "../../i18n";
import { API_Post_Forgot } from "../../util/api";

interface ComponentStruct {
  setUsername: (username: string) => void;
  setStep: (id: number) => void;
}

const Step1: React.VFC<ComponentStruct> = ({ setStep, setUsername }) => {
  interface InputStruct {
    username: string;
  }

  interface ErrorStruct {
    username: boolean;
  }

  const [Loading, setLoading] = useState<boolean>(false);

  const Data = useRef<InputStruct>({
    username: "",
  });

  const [Error, setError] = useState<ErrorStruct>({
    username: false,
  });

  const history = useHistory();

  const { t } = useTranslation();

  const POSTAPI = (): void => {
    setLoading(true);
    API_Post_Forgot(Data.current.username)
      .then((res) => {
        console.log(res.status)
        if (res.status) {
          setUsername(Data.current.username);
          setStep(1);
        } else {
          Swal.fire({
            icon: "warning",
            title: t("warning"),
            text: res.message,
            timer: 5000,
            timerProgressBar: true,
          });
        }
        setLoading(false);
      })
      .catch(() => {
        Swal.fire({
          icon: "warning",
          title: t("warning"),
          text: t("unable"),
          timer: 5000,
          timerProgressBar: true,
        });
        setLoading(false);
      });
  };

  const onSubmit = (): void => {
    if (Check_Email(Data.current.username, "username")) {
      POSTAPI();
    }
  };

  const onEnterKey = (event: KeyboardEvent): void => {
    event.key === "Enter" && onSubmit();
  };

  const changeData = (event: ChangeEvent<HTMLInputElement>): void => {
    Data.current = { ...Data.current, [event.target.id]: event.target.value };
  };

  const Check_Email = (value: string, id: string): boolean => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(value))) {
      setError((prevState) => ({ ...prevState, [id]: false }));
      return true;
    } else {
      document.getElementById(id)?.focus();
      setError((prevState) => ({ ...prevState, [id]: true }));
      document.getElementById(id)?.focus();
      return false;
    }
  };

  return (
    <>
      <div className="mb-2">
        <div className="field">
          <label className="label has-text-white" htmlFor="username">
            {t("username")}
          </label>
          <div className="control has-icons-left">
            <input
              disabled={Loading}
              autoComplete="true"
              autoFocus
              id="username"
              type="email"
              onKeyPress={onEnterKey}
              onChange={changeData}
              placeholder={t("username_placeholder")}
              className={`input ${Error.username ? "is-danger" : ""} ${
                Loading ? "is-loading" : ""
              }`}
            />
            <span className="icon is-small is-left">
              <FaEnvelope />
            </span>
          </div>
          {Error.username && (
            <p className="help is-danger">{t("username_error")}</p>
          )}
        </div>
      </div>
      <button
        onClick={() => onSubmit()}
        className={`button is-primary is-fullwidth mt-5 ${
          Loading ? "is-loading" : ""
        }`}
      >
        {t("confirm")}
      </button>
      <button
        onClick={() => history.push("/login")}
        className={`button is-primary is-outlined is-fullwidth mt-2 ${
          Loading ? "is-loading" : ""
        }`}
      >
        {t("back")}
      </button>
    </>
  );
};

export default Step1;
