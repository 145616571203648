import React from 'react';
import Swal from 'sweetalert2';

const Order = () => {

    interface DataStruct {
        id: number
        qty: number
    };

    const onSubmit = () => {
        Swal.fire({
            icon: "warning",
            title: "คำเตือน",
            text: "คุณต้องการที่จะ สั่งซื้อสินค้า ใช่ หรือ ไม่?",
            confirmButtonText:"ใช่",
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'ไม่',
            timer: 5000,
            timerProgressBar: true
        }).then(res => {
            if (res.isConfirmed) {
                alert("Hello");
            }
        });
    }

    return (
        <>
        <div className="container">
            <div className="card mt-2">
                <header className="card-header">
                    <p className="card-header-title">ข้อมูลติดต่อกลับ</p>
                </header>
                <div className="card-content">
                                <table className='table' width='100%'>
                                    <tr>
                                       <th> ชื่อ - นามสกุล </th>
                                       <td>asdasdasd</td>
                                    </tr>
                                    <tr>
                                       <th> เบอร์โทรติดต่อ </th>
                                       <td>asdasd</td>
                                    </tr>
                                    <tr>
                                       <th> อีเมล </th>
                                       <td>asdasd</td>
                                    </tr>
                                    <tr>
                                       <th> ที่อยู่สำหรับจัดส่ง </th>
                                       <td><textarea className='input' cols={30} rows={40}></textarea></td>
                                    </tr>
                                 </table>
                </div>
            </div>
            <div className="card mt-2 mb-2">
                <header className="card-header">
                    <p className="card-header-title">รายการสั่งซื้อ</p>
                </header>
                <div className="card-content">
                    <table className='table' width="100%">
                        <tbody>
                            <tr style={{textAlign: 'center'}}>
                                <th>รหัสสินค้า</th>
                                <th>ชื่อสินค้า</th>
                                <th>ราคา</th>
                                <th>จำนวน</th>
                                <th>รวม</th>
                            </tr>
                            <tr style={{textAlign: 'center'}}>
                                <td width="10%">1</td>
                                <td>asdasd</td>
                                <td width="15%">12312</td>
                                <td width="10%">1</td>
                                <td width="15%">123</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th align='center' colSpan={4}>ราคารวม</th>
                                <th align='center'>10000</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
        <div style={{position: 'fixed', bottom: 0, width: '100%'}}>
            <button className="button is-success is-fullwidth" onClick={() => onSubmit()}>สั่งซื้อ</button>
        </div>
        </>
    );
}

export default Order;