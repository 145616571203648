export const TRANSLATIONS_TH = {
	"login": "เข้าสู่ระบบ",
	"register": "สมัครสมาชิก",
	"rememberme": "จดจำ",
	"showpassword": "แสดงรหัสผ่าน",
	"forgot": "ลืมรหัสผ่าน",
	"username": "ชื่อผู้ใช้งาน",
	"username_placeholder": "อีเมลของท่าน",
	"username_error": "กรุณากรอกชื่อผู้ใช้งานให้ถูกต้อง",
	"password": "รหัสผ่าน",
	"password_placeholder": "รหัสผ่าน 10 ตัวขึ้นไป",
	"password_error": "กรุณากรอกรหัสผ่านให้ถูกต้อง",
	"newpassword": "รหัสผ่านใหม่",
	"send_code": "ส่งโค้ดอีกครั้ง",
	"confirmpassword": "ยืนยันรหัสผ่าน",
	"firstname": "ชื่อจริง",
	"fistname_placeholder": "ชื่อจริงของท่าน",
	"firstname_error": "กรุณากรอกชื่อจริงให้ถูกต้อง",
	"lastname": "นามสกุล",
	"lastname_placeholder": "นามสกุลของท่าน",
	"lastname_error": "กรุณากรอกนามสกุลให้ถูกต้อง",
	"tel": "เบอร์โทรติดต่อ",
	"tel_paceholder": "เบอร์โทรของท่าน",
	"tel_error": "กรุณากรอกเบอร์โทรให้ถูกต้อง",
	"gender": "เพศ",
	"gender_paceholder": "เลือกเพศ",
	"gender_error": "กรุณาเลือกเพศให้ถูกต้อง",
	"code_forgot": "โค้ดยืนยัน",
	"code_forgot_paceholder": "กรุณากรอกโค้ดที่ได้รับจากอีเมล",
	"code_forgot_error": "กรุณากรอกโค้ดให้ถูกต้อง",
	"male": "ชาย",
	"female": "หญิง",
	"job": "อาชีพ",
	"job_paceholder": "เลือกอาชีพ",
	"job_error": "กรุณาเลือก อาชีพ ให้ถูกต้อง",
	"country": "ประเทศ",
	"country_paceholder": "เลือกประเทศ",
	"country_error": "กรุณาเลือก ประเทศ ให้ถูกต้อง",
	"termsofuse": "ข้อกำหนดการใช้งาน",
	"privacy": "ความเป็นส่วนตัว",
	"back": "กลับ",
	"error": "เกิดข้อผิดพลาด",
	"success": "สำเร็จ",
	"warning": "คำเตือน",
	"unable": "ไม่สามารถเชื่อมต่อฐานข้อมูลได้",
	"yes": "ใช่",
	"no": "ไม่",
	"confirm": "ยืนยัน",
}