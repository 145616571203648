import React, { useContext, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import Load from '../component/load';
import { API_Me } from '../util/api';
import { AuthContext } from '../util/auth-context';
import Brand from './brand';
import Brand_id from './brand/brand_id';
import Home from './Home';
import Product_id from './Product_id';
import '../loading.css'


const Loading = ()=> {

    const context = useContext(AuthContext);

    const history = useHistory();
	
	const Clear = () => {
		localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        history.push('/login');
    }

     useEffect(() => {
        if (localStorage.hasOwnProperty('token')) {
             API_Me().then(res => {
                if (res.status === 200) {
                    if (res.data.status) {
                        context.auth[1](true);
                    }
                } else {
                    Clear();
                }
            })
        } else {
            Clear();
        }
    }, []);


    if (!context.auth[0]) {
        return <Load />
    }

    return (
        <>
            <Route exact path="/" component={Home} />
            <Route exact path="/brand" component={Brand} />
            <Route exact path="/brand/:id" component={Brand_id} />
            <Route exact path="/product/:id" component={Product_id} />
        </>
    );
}


export default Loading;