import React, { createContext, useState } from 'react';

export const AuthContext = createContext<any>([]);

export const AuthProvider : React.FC = ({ children }) => {

    const [Auth, setAuth] = useState(false);

    const store = {
        auth: [Auth, setAuth],
    }

    return (
        <AuthContext.Provider value={store}>
            { children }
        </AuthContext.Provider>
    )
}
