import React, {
  ChangeEvent,
  KeyboardEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import Logo from "../asset/logo.png";
import { FaUser, FaLock, FaFacebookSquare, FaGoogle } from "react-icons/fa";
import Swal from "sweetalert2";
import { API_Login, API_Login_OAuth } from "../util/api";
import { AuthContext } from "../util/auth-context";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import Oauth from "./Oauth";
import { useTranslation } from "react-i18next";
import "../i18n";

const Login = () => {
  interface DataType {
    username: string;
    password: string;
    firstname: string;
    lastname: string;
    status: number;
  }

  const { t } = useTranslation();

  const Data = useRef<DataType>({
    username: "",
    password: "",
    firstname: "",
    lastname: "",
    status: 0,
  });

  const [Error, setError] = useState({
    username: false,
    password: false,
  });

  const context = useContext(AuthContext);

  const [Remeberme, setRemeberme] = useState<boolean>(false);

  const [ShowPassword, setShowPassword] = useState<boolean>(false);

  const [StatusOauth, setStatusOauth] = useState(false);

  const [Version, setVersion] = useState<string>("2.0.0");

  const history = useHistory();

  const changeData = (event: ChangeEvent<HTMLInputElement>): void => {
    let data = {
      ...Data.current,
      [event.target.id]: event.target.value,
    };
    Data.current = data;
  };

  const onSubmitLogin = () => {
    if (Check_Email(Data.current.username, "username") && Check_Password()) {
      API_Login(Data.current.username, Data.current.password)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status) {
              context.auth[1](true);
              if (Remeberme) {
                let data = JSON.stringify({
                  username: Data.current.username,
                  password: Data.current.password,
                });
                localStorage.setItem("remeberme", data);
              } else {
                localStorage.removeItem("remeberme");
              }
              localStorage.setItem("token", res.data.token["access_token"]);
              localStorage.setItem(
                "refresh_token",
                res.data.token["refresh_token"]
              );
            }

            Swal.fire({
              icon: res.data.status ? "success" : "error",
              title: res.data.status ? t("success") : t("error"),
              text: res.data.message,
              timer: 5000,
              timerProgressBar: true,
            }).then(() => {
              if (res.data.status) {
                window.location.href = "/";
              }
            });
          } else {
            Swal.fire({
              icon: "error",
              title: t("error"),
              text: "ชื่อผู้ใช้งาน หรือ รหัสผ่านไม่ถูกต้อง",
              timer: 5000,
              timerProgressBar: true,
            });
          }
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: t("error"),
            text: t("unable"),
            timer: 5000,
            timerProgressBar: true,
          });
        });
    }
  };

  const LoginGoogleFailed = (data: any) => {
    Swal.fire({
      icon: "error",
      title: "เกิดข้อผิดพลาด",
      text: "ไม่สามารถเชื่อมต่อกับผู้ให้บริการปลายทางได้",
      timer: 5000,
      timerProgressBar: true,
    });
  };

  const onSubmitFacebook = (data: any) => {
    const name = data.name.split(" ");
    Data.current.username = data.email;
    Data.current.password = data.id;
    Data.current.firstname = name[0];
    Data.current.lastname = name[name.length - 1];
    Data.current.status = 1;
    LoginOauth();
  };

  const onSubmitGoogle = (data: any) => {
    Data.current.username = data.profileObj.email;
    Data.current.password = data.profileObj.googleId;
    Data.current.firstname = data.profileObj.familyName;
    Data.current.lastname = data.profileObj.givenName;
    Data.current.status = 2;
    LoginOauth();
  };

  const LoginOauth = () => {
    API_Login_OAuth(
      Data.current.username,
      Data.current.password,
      Data.current.status
    )
      .then((res) => {
        if (res.data.status) {
          localStorage.setItem("token", res.data.token["access_token"]);
          localStorage.setItem(
            "refresh_token",
            res.data.token["refresh_token"]
          );
          Swal.fire({
            icon: res.data.status ? "success" : "error",
            title: res.data.status ? t("success") : t("error"),
            text: res.data.message,
            timer: 5000,
            timerProgressBar: true,
          }).then(() => {
            if (res.data.status) {
              window.location.href = "/";
            }
          });
        } else {
          if (res.data.message === "ไม่พบชื่อผู้ใช้งาน") {
            setStatusOauth(true);
          } else {
            Swal.fire({
              icon: "error",
              title: t("error"),
              text: res.data.message,
              timer: 5000,
              timerProgressBar: true,
            });
          }
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: t("error"),
          text: t("unable"),
          timer: 5000,
          timerProgressBar: true,
        });
      });
  };

  const BackToLogin = () => {
    setStatusOauth(false);
  };

  const onEnterKey = (event: KeyboardEvent): void => {
    event.key === "Enter" && onSubmitLogin();
  };

  const Check_Password = (): boolean => {
    let status = false;

    if (Data.current.password !== "") {
      if (Data.current.password.length >= 10) {
        status = true;
      }
    }

    if (status) {
      setError((preState) => ({
        ...preState,
        password: false,
      }));
      return true;
    } else {
      setError((preState) => ({
        ...preState,
        password: true,
      }));
      document.getElementById("password")?.focus();
      return false;
    }
  };

  const Check_Email = (value: string, id: string): boolean => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(value))) {
      setError((prevState) => ({ ...prevState, [id]: false }));
      return true;
    } else {
      document.getElementById(id)?.focus();
      setError((prevState) => ({ ...prevState, [id]: true }));
      document.getElementById(id)?.focus();
      return false;
    }
  };

  const Get_Version = () => {
    // @ts-ignore
    sketchup.get_version();
  };

  const CheckRemeberme = () => {
    if (localStorage.hasOwnProperty("remeberme")) {
      let data = JSON.parse(localStorage.getItem("remeberme") || "");
      setRemeberme(true);
      (document.getElementById("username") as HTMLInputElement).value =
        data["username"];
      (document.getElementById("password") as HTMLInputElement).value =
        data["password"];
      Data.current.username = data["username"];
      Data.current.password = data["password"];
    }
  };

  const setBackground = () => {
    document.body.style.backgroundColor = "#252c40";
  };

  useEffect(() => {
    //Get_Version();
    CheckRemeberme();
    setBackground();
  }, []);

  return (
    <>
      <div className="container">
        <div
          className="is-flex is-flex-column is-justify-content-center is-align-items-center"
          style={{ minHeight: "100vh", maxHeight: "100%" }}
        >
          {!StatusOauth ? (
            <div style={{ width: "400px" }}>
              <img src={Logo} alt="NoImage" className="has-text-centered" />
              <form>
                <div className="mb-2">
                  <div className="field">
                    <label className="label has-text-white" htmlFor="username">
                      {t("username")}
                    </label>
                    <div className="control has-icons-left">
                      <input
                        autoComplete="true"
                        autoFocus
                        id="username"
                        type="email"
                        onKeyPress={onEnterKey}
                        onChange={changeData}
                        placeholder={t("username_placeholder")}
                        className={`input ${Error.username ? "is-danger" : ""}`}
                      />
                      <span className="icon is-small is-left">
                        <FaUser />
                      </span>
                    </div>
                    {Error.username && (
                      <p className="help is-danger">{t("username_error")}</p>
                    )}
                  </div>
                </div>
                <div className="mb-2">
                  <div className="field">
                    <label className="label has-text-white" htmlFor="password">
                      {t("password")}
                    </label>
                    <div className="control has-icons-left">
                      <input
                        autoComplete="true"
                        id="password"
                        type={ShowPassword ? "text" : "password"}
                        onKeyPress={onEnterKey}
                        onChange={changeData}
                        placeholder={t("password_placeholder")}
                        className={`input ${Error.password ? "is-danger" : ""}`}
                      />
                      <span className="icon is-small is-left">
                        <FaLock />
                      </span>
                    </div>
                    {Error.password && (
                      <p className="help is-danger">{t("password_error")}</p>
                    )}
                  </div>
                </div>

                <div className="is-flex">
                  <div className="field mr-5">
                    <div className="control">
                      <label
                        htmlFor="remeberme"
                        className="checkbox has-text-white"
                      >
                        <input
                          checked={Remeberme}
                          onChange={() => setRemeberme(!Remeberme)}
                          id="remeberme"
                          type="checkbox"
                        />{" "}
                        {t("rememberme")}
                      </label>
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <label
                        htmlFor="showpassword"
                        className="checkbox has-text-white"
                      >
                        <input
                          checked={ShowPassword}
                          onChange={() => setShowPassword(!ShowPassword)}
                          id="showpassword"
                          type="checkbox"
                        />{" "}
                        {t("showpassword")}
                      </label>
                    </div>
                  </div>
                </div>
              </form>
              <button
                onClick={() => onSubmitLogin()}
                className={`button is-primary is-fullwidth mt-3`}
              >
                {t("login")}
              </button>
              <button
                onClick={() => history.push("/register")}
                className="button is-outlined is-primary is-fullwidth mt-2"
              >
                {t("register")}
              </button>
              <button
                onClick={() => history.push("/forgot")}
                className="button is-outlined is-primary is-fullwidth mt-2"
              >
                {t("forgot")}
              </button>
              {/* <FacebookLogin
                appId="508825343329027"
                cssClass="button is-fullwidth mt-2"
                fields="name,email"
                icon={<FaFacebookSquare style={{ marginRight: "5px" }} />}
                callback={onSubmitFacebook}
              />
              <GoogleLogin
                render={(renderProps) => (
                  <button
                    onClick={renderProps.onClick}
                    className="button is-fullwidth mt-2"
                  >
                    <FaGoogle style={{ marginRight: "5px" }} />
                    Login with Google
                  </button>
                )}
                clientId="342070927336-5lddqvujihefeitdl979nc0795ifhht9.apps.googleusercontent.com"
                className="button"
                buttonText="gello"
                onSuccess={onSubmitGoogle}
                onFailure={LoginGoogleFailed}
              /> */}
            </div>
          ) : (
            <Oauth data={Data.current} back={BackToLogin} />
          )}
        </div>
      </div>
      <div className="fixed-bottom">
        <h5 className="text-center has-text-white">Version {Version}</h5>
      </div>
    </>
  );
};

export default Login;
