/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import SideBar from '../../component/sidebar';
import BrandCard from '../../component/brand_card';
import { API_Brand } from '../../util/api';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

const Brand = () => {

    interface Brand {
        id_brand: string
        name_brand: string
        image_brand: string
        skp_product: string
    }

    const [Data, setData] = useState<Array<Brand>>([])
    const [Total, setTotal] = useState(0);
    const [Page, setPage] = useState(1);

    const Get_Data = () => {
        API_Brand('').then(res => {
            setData(res.data)
            setTotal(res.total)
        }).catch(() => {

        });
    }

    const onPage = (id : number) => {
        if (id > 0 && id <= Math.ceil(Total / 50)) {
            setPage(id);
        }
    }

    useEffect(() => {
        Get_Data();
    }, []);

    return (
        <>
        <SideBar />
        <div className="is-flex is-flex-wrap-wrap is-justify-content-center" style={{'marginLeft': '60px'}}>
            {
                Data.map((item, index) => (
                    <BrandCard data={item} key={index} />
                ))
            }
        </div>
        <div className="d-flex justify-content-center mt-5 mb-2" style={{'marginLeft': '60px'}}>
            <nav className="pagination is-centered is-rounded" style={{margin: 0}} role="navigation" aria-label="pagination">
                <a onClick={() => onPage(Page - 1)} className="pagination-previous"><FaAngleLeft /></a>
                <a onClick={() => onPage(Page + 1)} className="pagination-next"><FaAngleRight /></a>
                <ul className="pagination-list">
                    {
                        Total > 0 &&
                        Array.from(Array(Math.ceil(Total / 50)), (v, i) => (
                            <li key={i}><a onClick={() => onPage(i+1)} className={`pagination-link ${i+1 === Page ? 'is-current' : ''}`} aria-label={`Goto page ${i+1}`}>{i+1}</a></li>
                        ))
                    }
                </ul>
            </nav>
        </div>
        </>
    );
}

export default Brand;