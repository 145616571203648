
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import {TRANSLATIONS_EN} from './locales/en/translations';
import {TRANSLATIONS_TH} from './locales/th/translations';

const detectionOptions = {
	lookupLocalStorage: 'i18nextLng',
	caches: ['localStorage'],
}

i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
	resources: {
		en: {
			translation: TRANSLATIONS_EN
		},
		th: {
			translation: TRANSLATIONS_TH
		}
	},
	lng: 'th',
	detection: detectionOptions,
	fallbackLng: 'th-TH'
});

export const swtichLanguage = (value : string) => {
	i18n.changeLanguage(value);
}
