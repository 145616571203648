import React, { FormEvent } from 'react';
import { FaSearch} from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { API_Image_Brand, API_Image_Product } from '../util/api';

interface Struct {
    data: {
        id_product: string,
        name_product: string,
        image_product: string,
        image_brand: string,
        skp_product: string,
        material: boolean,
    },
    size : number
}

const Product : React.FC<Struct> = (props) => {


    const history = useHistory()
    const ContextMenu = (e: FormEvent) => {
        e.preventDefault()
    }

    const takemodel = () => {
        // @ts-ignore
        sketchup.place(props.data, localStorage.getItem('token'));
    }

    const ZoomController = () => {
        if (props.size > 0 && props.size < 6) {
            return "zoom-" + props.size;
        }
    }

    return (
        <div onContextMenu={ContextMenu} className={`product ${ZoomController()}`}>
            <div onClick={() => takemodel()} className="overlay"></div>
            <div className="logo-brand">
                <LazyLoadImage
                src={API_Image_Brand(props.data.image_brand)}
                effect="opacity"
                width={ props.size >= 2 ? '30px' : '15px'} />
                <FaSearch onClick={() => history.push("/product/" + props.data.id_product)} id="search" />
            </div>
            <LazyLoadImage
            src={API_Image_Product(props.data.image_product)}
            effect="opacity"
            width="100%" />
            <p onClick={() => takemodel()}>{props.data.name_product}</p>
        </div>
    );
}

export default Product;