import axios from "axios";

//const API = "http://localhost:5421/api/v1/";
// const API = "http://localhost:9999/api/v1/";
//const API = "http://139.59.98.79:5420/api/v1/";
const API = "https://api.product-connect.com/api/v1/";

axios.interceptors.request.use(
  (request) => {
    if (request.url !== API + "auth/refresh") {
      request.headers.Authorization = `Bearer ${window.localStorage.getItem(
        "token"
      )}`;
    }
    return request;
  },
  (err) => {
    return Promise.reject(err.response);
  }
);


export const API_Job = () => {
  return axios
    .get(`${API}job/`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Work_All = () => {
  return axios
    .get(`${API}work/all/1`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Work_Brand = (id: string) => {
  return axios
    .get(`${API}work/brand/${id}`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Work = () => {
  return axios
    .get(`${API}work/`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Category = (id: string) => {
  return axios
    .get(`${API}category/${id}`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Kind = (id: string) => {
  return axios
    .get(`${API}kind/${id}`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Member = (
  username: string,
  password: string,
  firstname: string,
  lastname: string,
  gender: string,
  job: string,
  tel: string,
  country: string,
  status?: number
) => {
  return axios
    .post(`${API}member/`, {
      username: username,
      password: password,
      firstname: firstname,
      lastname: lastname,
      gender: gender === "1" ? true : false,
      id_job: job,
      tel: tel,
      country: country,
      oauth: status,
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Login = (username: string, password: string) => {
  return axios
    .post(`${API}auth/login`, {
      username: username,
      password: password,
    })
    .then((res) => res)
    .catch((err) => err);
};

export const API_Login_OAuth = (
  username: string,
  password: string,
  status: number
) => {
  return axios
    .post(`${API}auth/login/oauth`, {
      username: username,
      password: password,
    })
    .then((res) => res)
    .catch((err) => err);
};

export const API_Logout = () => {
  return axios
    .post(
      `${API}auth/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Search = (
  name: string,
  Work: string,
  category: string,
  kind: string,
  brand: string,
  page: number
) => {
  let url = `${API}product/search?name=${name ||
    ""}&work=${Work}&category=${category}&kind=${kind}&brand=${brand}&page=${page}`;
  return axios
    .get(url)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Product = (id: string) => {
  return axios
    .get(`${API}product/find/${id}`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Detail = (id: string) => {
  return axios
    .get(`${API}detail/${id}`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Brand = (name: string) => {
  return axios
    .get(`${API}brand/search?name=${name || ""}`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Brand_Find = (id: string) => {
  return axios
    .get(`${API}brand/${id}`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Image_Brand = (name: string) => {
  return `${API}download/brand/img/${name}`;
};

export const API_Image_Product = (name: string) => {
  return `${API}download/product/img/${name}`;
};

export const API_gltf_product = (name: string) => {
  return `${API}download/product/gltf/${name}`;
};

// GET API GET Data Me
export const API_Me = () => {
  return axios
    .post(API + "auth/me", {})
    .then((res) => res)
    .catch((err) => err);
};

export const API_Post_Forgot = (username: String) => {
  return axios
    .post(API + "member/forgot", {
      username: username,
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Post_Check = (Username: String, Code: string) => {
  return axios
    .post(API + "member/code", {
      username: Username,
      code: Code,
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Post_Rest = (
  Username: String,
  Code: String,
  password: String
) => {
  return axios
    .post(API + "member/reset", {
      username: Username,
      code: Code,
      password: password,
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};
