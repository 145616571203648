import React, { FormEvent } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { useHistory } from 'react-router';
import { API_Image_Brand } from '../util/api';

interface Struct {
    data: {
        id_brand: string,
        name_brand: string,
        image_brand: string,
        skp_product: string
    }
}

const Brand_Card : React.FC<Struct> = (props) => {

    const history = useHistory();

    const ContextMenu = (e: FormEvent) => {
        e.preventDefault()
    }

    return (
        <div onClick={() => history.push('/brand/' + props.data.id_brand)} onContextMenu={ContextMenu} className="product">
            <div className="overlay"></div>
            <LazyLoadImage
            src={API_Image_Brand(props.data.image_brand)}
            effect="opacity"
            width="100%" />
            <p>{props.data.name_brand}</p>
        </div>
    );
}

export default Brand_Card;