/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, MouseEvent, MouseEventHandler, useRef, useContext } from 'react';
import SideBar from '../component/sidebar';
import Product from '../component/product';
import Search from '../component/search';
import { API_Search } from '../util/api';
import Swal from 'sweetalert2';
import { FaAngleLeft, FaAngleRight, FaSearchMinus, FaSearchPlus } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { DataContext } from '../util/data-context';

const Home = ()=> {

    const [Data, setData] = useState([]);
    const dataContext = useContext(DataContext);

    const [InputData, setInputData] = useState({
        name: '',
        work: '',
        category: '',
        kind: '',
        brand: '',
        page: 0,
    });

    const [Loading, setLoading] = useState(false);

    const [Total, setTotal] = useState(0);

    const [Zoom, setZoom] = useState(3);

    const Get_Data = () => {
        setLoading(true);
        API_Search(InputData.name, InputData.work, InputData.category, InputData.kind, InputData.brand, InputData.page).then(res => {
        setData(res.data);
        setTotal(res.total);
        localStorage.setItem("search", JSON.stringify(InputData));
        setLoading(false);
      }).catch(() => {
        Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด',
            text: 'เกิดข้อผิดพลาดจากทางเซิฟเวอร์',
            timer: 5000,
            timerProgressBar: true
        });
        setLoading(false);
    });
    }

    const SetFromSearch = (Search_Box : string, Work : string, Category : string, Kind : string, Brand : string) => {
        setInputData(preState => ({
            ...preState,
            name: Search_Box,
            work: Work,
            category: Category,
            kind: Kind,
            brand: Brand,
            page: dataContext.page
        }));
    }

    const onPage = (id : number) => {
        if (id > 0 && id <= Math.ceil(Total / 50)) {
            setInputData(preState => ({
                ...preState,
                page: id,
            }));
            dataContext.page = id;
        }
    }

    const SetZoomController = (type : number) => {
        switch(type) {
            case 1:
                if (Zoom < 5) {
                    setZoom(Zoom + 1);
                }
                break;
            default:
                if (Zoom > 1) {
                    setZoom(Zoom - 1);
                }
                break;
        }
    } 

    useEffect(() => {
        Get_Data();
    }, [InputData]);


  return (
    <>
    <SideBar />
    <Search Set={SetFromSearch} />
    <div className="is-flex is-justify-content-space-between" style={{marginLeft: '70px', marginRight: '60px', paddingTop: '60px'}}>
        <div>ค้นหาสินค้าเจอ <b className="has-text-primary">{Total}</b> ตัว</div>
        <div>
            <button data-for="zoom" data-tip="ขยายขนาดช่องสินค้า" onClick={() => SetZoomController(1)} className="button is-outlined is-link is-small mr-1"><FaSearchPlus /></button>
            <button data-for="zoom" data-tip="ลดขนาดช่องสินค้า" onClick={() => SetZoomController(0)} className="button is-outlined is-link is-small"><FaSearchMinus/></button>
            <ReactTooltip id="zoom" place="bottom" effect="solid" />
        </div>
    </div>
    <div className="is-flex is-flex-wrap-wrap is-justify-content-center" style={{'marginLeft': '60px'}}>
        {
            !Loading ?
            Data.map((item, index) => (
                <Product size={Zoom} key={index} data={item} />
            ))
            :
            <div className="d-flex justify-content-center">
                <div className="spinner-border text-link" role="status"></div>
            </div>

        }
    </div>
    <div className="d-flex justify-content-center mt-5 mb-2" style={{'marginLeft': '60px'}}>
        <nav className="pagination is-centered is-rounded" style={{margin: 0}} role="navigation" aria-label="pagination">
            <a onClick={() => onPage(InputData.page - 1)} className="pagination-previous"><FaAngleLeft /></a>
            <a onClick={() => onPage(InputData.page + 1)} className="pagination-next"><FaAngleRight /></a>
            <ul className="pagination-list">
                {
                    Array.from(Array(Math.ceil(Total / 50)), (v, i) => (
                        <li key={i}><a onClick={() => onPage(i+1)} className={`pagination-link ${i+1 === InputData.page ? 'is-current' : ''}`} aria-label={`Goto page ${i+1}`}>{i+1}</a></li>
                    ))
                }
            </ul>
        </nav>
    </div>
    </>
  );
}

export default Home;
