/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';
import SideBar from '../../component/sidebar';
import Product from '../../component/product';
import { API_Search } from '../../util/api';
import { useParams } from 'react-router';
import Swal from 'sweetalert2';
import SelectKindBrand from '../../component/selectkindbrand';
import ReactTooltip from 'react-tooltip';
import { FaAngleLeft, FaAngleRight, FaSearchMinus, FaSearchPlus } from 'react-icons/fa';
import { DataContext } from '../../util/data-context';

const Brand_ID = () => {

    
    interface Product {
        id_product: string
        name_product: string
        image_product: string
        skp_product: string
        material: boolean
        image_brand: string
    }
    
    const param = useParams<{
        id : string
    }>();

    const dataContext = useContext(DataContext);

    const [Total, setTotal] = useState(0);
    const [Data, setData] = useState<Array<Product>>([]);
    const [Zoom, setZoom] = useState(3);
    const [Select, setSelect] = useState({
        work: '',
        category: '',
        kind: '',
        page: dataContext.page
    });

    const Get_Data = () => {
        API_Search("", Select.work, Select.category, Select.kind, param.id, Select.page).then(res => {
            setData(res.data);
            setTotal(res.total);
        }).catch(() => {
            Swal.fire({
                icon: 'success',
                title: 'เกิดข้อผิดพลาด',
                text: 'เกิดข้อผิดพลาดจากทางเซิฟเวอร์',
                timer: 5000,
                timerProgressBar: true
            });
        });
    }

    const SetKindData = (type : string, id : string) => {
        dataContext.page = 1;
        setSelect(preState => ({
            ...preState,
            [type]: id,
            page: dataContext.page
        }));
    }

    const SetZoomController = (type : number) => {
        switch(type) {
            case 1:
                if (Zoom < 5) {
                    setZoom(Zoom + 1);
                }
                break;
            default:
                if (Zoom > 1) {
                    setZoom(Zoom - 1);
                }
                break;
        }
    } 

    const onPage = (id : number) => {
        if (id > 0 && id <= Math.ceil(Total / 50)) {
            setSelect(preState => ({
                ...preState,
                page: id,
            }));
        }
    }

    useEffect(() => {
        Get_Data();
    }, [Select]);

    return (
    <>
        <SideBar />
        <div className="is-flex is-justify-content-flex-start navbar-bar">
                <div className="col-lg-12 mt-1 mb-1">
                    <div className="is-flex">
                        <SelectKindBrand id={param.id} set={SetKindData} />
                        <ReactTooltip id="typesearch" place="bottom" effect="solid" />
                    </div>
                </div>
        </div>
        <div className="is-flex is-justify-content-space-between" style={{marginLeft: '70px', marginRight: '60px', paddingTop: '60px'}}>
            <div>ค้นหาสินค้าเจอ <b className="has-text-link">{Total}</b> ตัว</div>
            <div>
                <button data-for="zoom" data-tip="ขยายขนาดช่องสินค้า" onClick={() => SetZoomController(1)} className="button is-outlined is-link is-small mr-1"><FaSearchPlus /></button>
                <button data-for="zoom" data-tip="ลดขนาดช่องสินค้า" onClick={() => SetZoomController(0)} className="button is-outlined is-link is-small"><FaSearchMinus/></button>
                <ReactTooltip id="zoom" place="bottom" effect="solid" />
            </div>
        </div>
        <div className="is-flex is-flex-wrap-wrap is-justify-content-center" style={{'marginLeft': '60px'}}>
            {
                Data.map((item, index) => (
                    <Product size={Zoom} key={index} data={item} />
                ))
            }
        </div>
        <div className="d-flex justify-content-center mt-5 mb-2" style={{'marginLeft': '60px'}}>
            <nav className="pagination is-centered is-rounded" style={{margin: 0}} role="navigation" aria-label="pagination">
                <a onClick={() => onPage(Select.page - 1)} className="pagination-previous"><FaAngleLeft /></a>
                <a onClick={() => onPage(Select.page + 1)} className="pagination-next"><FaAngleRight /></a>
                <ul className="pagination-list">
                    {
                        Total > 0 &&
                        Array.from(Array(Math.ceil(Total / 50)), (v, i) => (
                            <li key={i}><a onClick={() => onPage(i+1)} className={`pagination-link ${i+1 === Select.page ? 'is-current' : ''}`} aria-label={`Goto page ${i+1}`}>{i+1}</a></li>
                        ))
                    }
                </ul>
            </nav>
        </div>
    </>
    );
}

export default Brand_ID;