import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaList } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { API_Work_All } from '../util/api';

interface ItemStruct {
    id_work: string
    name_work: string
    TbCategorys: Array<Category>
}

interface Category {
    id_category: string
    name_category: string
    TbKinds: Array<Kind>
}

interface Kind {
    id_kind: string
    name_kind: string
}

interface Struct {
    set: (type : string, id : string) => void
}

const Select_Kind = (props : Struct) => {

    const [Toggle, setToggle] = useState(false);
    const [Data, setData] = useState<Array<ItemStruct>>([]);
    const [Select, setSelect] = useState(-1);

    const onEsc = () => {
        window.addEventListener('keydown', (e => {
            e.keyCode === 27 && setToggle(false);
        }));
    }

    const Get_Data = () => {
        API_Work_All().then(res => {
            setData(res.data);
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'เกิดข้อผิดพลาดจากทางเซิฟเวอร์',
                timer: 5000,
                timerProgressBar: true
            });
        });
    }

    const ClickSelect = (type: string, id : string) => {
        if (type !== 'work') {
            setToggle(false);
        }
        props.set(type, id);
    }

    useEffect(() => {
        onEsc();
        Get_Data();
    }, []);

    return (
        <>
        <button data-tip="เลือกประเภท" onClick={() => setToggle(!Toggle)} data-for="typesearch" style={{padding: '9px', width: '40px', height: '100%'}} className="button ml-2 is-small"><FaList style={{fontSize: '20px'}} /></button>
        <div className={`modal ${Toggle ? 'is-active' : ''}`}>
            <div className="modal-background" onClick={() => setToggle(!Toggle)} />
            <div className="modal-card">
                <header className="modal-card-head">
                <p className="modal-card-title">เลือกหมวดงานต่างๆ</p>
                <button onClick={() => setToggle(!Toggle)} className="delete" aria-label="close" />
                </header>
                <section className="modal-card-body">
                    {
                        Select > -1 ?
                        <>
                        <div className="is-flex is-aligin-items-center">
                            <button onClick={() => setSelect(-1)} className="button is-small mb-4 is-link"><FaArrowLeft /></button>
                            <h5 className="subtitle is-5 ml-4">{Data[Select].name_work}</h5>
                        </div>
                            <div className="is-flex is-justify-content-center is-flex-wrap-wrap">
                                {
                                    Data[Select].TbCategorys.map((item, index) => (
                                        <ul key={index} className="list-category">
                                            <b onClick={() => ClickSelect('category', item.id_category)}>{item.name_category}</b>
                                            <hr className="mb-1 mt-1" />
                                            {
                                                item.TbKinds.map((item2, index2) => (
                                                    <li key={index2} onClick={() => ClickSelect('kind', item2.id_kind)}>{item2.name_kind}</li>
                                                ))
                                            }
                                        </ul>
                                    ))
                                }
                            </div>
                        </>
                        :
                        Data.length > 0 &&
                        <div className="is-flex is-justify-content-center is-flex-wrap-wrap">
                            {
                                Data.map((item, index) => (
                                    <button key={index}
                                        onClick={() => {
                                            setSelect(index);
                                            ClickSelect('work', item.id_work);
                                        }}
                                        className="button mr-2 mb-2 is-fullwidth"> {item.name_work}
                                    </button>
                                ))
                            }
                        </div>
                    }
                </section>
            </div>
        </div>
        </>
    );
}

export default Select_Kind;