import React, { useEffect, useState } from 'react';
import { FaCity, FaTimesCircle } from 'react-icons/fa';
import { API_Brand, API_Image_Brand } from '../util/api';

interface ItemStruct {
    set: (id : string) => void
}

interface DataStruct {
    id_brand: string
    name_brand: string
    image_brand: string
}

const Select_Brand = (props : ItemStruct) => {

    const [Toggle, setToggle] = useState(false);
    const [Image, setImage] = useState('');
    const [Data, setData] = useState<Array<DataStruct>>([]);

    const Get_Brand = () => {
        API_Brand('').then(res => {
            setData(res.data)
        });
    }

    const ClickBrand = (id : string, image : string) => {
        props.set(id)
        setToggle(!Toggle);
        setImage(image);
    }

    const onEsc = () => {
        window.addEventListener('keydown', (e => {
            e.keyCode === 27 && setToggle(false);
        }));
    }

    useEffect(() => {
        onEsc();

        Get_Brand();
    }, []);

    return (
        <>
        <button data-tip="เลือกแบนด์" onClick={() => setToggle(!Toggle)} data-for="typesearch" style={{width:'40px', padding: '9px'}} className="button ml-1" >
            {
                Image ? 
                <img src={API_Image_Brand(Image)} width="30px" alt="NoImage" />
                :
                <FaCity style={{fontSize: '20px'}} />
            }
        </button>
        <div className={`modal ${Toggle ? 'is-active' : ''}`}>
            <div className="modal-background" onClick={() => setToggle(!Toggle)} />
            <div className="modal-card">
                <header className="modal-card-head">
                <p className="modal-card-title">เลือกแบนด์</p>
                <button onClick={() => setToggle(!Toggle)} className="delete" aria-label="close" />
                </header>
                <section className="modal-card-body">
                    <div className="is-flex is-justify-content-center is-flex-wrap-wrap">
                        <button onClick={() => ClickBrand('', '')} className="button mr-2 mb-2" style={{height: '70px'}}>
                            <FaTimesCircle className="has-text-danger" style={{fontSize: '45px'}} />
                        </button>
                        {
                            Data.map((item, index) => (
                                <button onClick={() => ClickBrand(item.id_brand, item.image_brand)} key={index} className="button mr-2 mb-2" style={{height: '70px'}}>
                                    <img src={API_Image_Brand(item.image_brand)} width="50px" alt="NoImage" />
                                </button>
                            ))
                        }
                    </div>
                </section>
            </div>
        </div>
        </>
    );
}

export default Select_Brand;